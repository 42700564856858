<template>
  <DashboardWrapper>
        <div class="container mx-auto">
            <div class="mb-4 mx-4 sm:mx-0">
                <h2 class="text-white uppercase text-xl font-extrabold">Wedstrijden</h2>
            </div>
        </div>
    </DashboardWrapper>
</template>

<script>
import DashboardWrapper from "@/views/wrappers/Dashboard";

export default {
name: "wedstrijden",
components: {
    DashboardWrapper
}
}
</script>

<style>
</style>